import { debounce } from 'lodash-es';

export const setDomFontSize = (): void => {
  let width = document.documentElement.clientWidth || document.body.clientWidth;
  // 只针对1500px以上的情况开启rem适配
  const remMinWidth = 1200;
  const radio = 1.5;
  let fontsize = (width <= remMinWidth ? remMinWidth : remMinWidth + Math.floor(width - remMinWidth) / radio) / 100 + 'px';
  (document.getElementsByTagName('html')[0].style as any)['font-size'] = fontsize;
};

let setDomFontSizeDebounce = debounce(setDomFontSize, 1500);
window.addEventListener('resize', setDomFontSizeDebounce);
