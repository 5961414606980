import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import queryString from 'query-string';
import store from 'store';
import TRANSLATION from './translation';
import { extend, map } from 'lodash-es';
import { LOCAL_LANG } from '@/config/constants';

const LANGUAGE_OPTIONS = [
  {
    label: '中文',
    i18n: 'zh',
  },
  {
    label: 'English',
    i18n: 'en',
  },
] as const;

export type Resources = {
  [x in (typeof LANGUAGE_OPTIONS)[number]['i18n']]: {
    ['translation']: {
      [key: string]: string;
    };
  };
};

const resources: Resources = extend({}, ...map(LANGUAGE_OPTIONS, (x) => ({ [x.i18n]: { translation: {} } })));

map(TRANSLATION, (translation, key) => {
  map(LANGUAGE_OPTIONS, (x) => {
    resources[x.i18n].translation[key] = translation[x.i18n];
  });
});

const initLang = (): string => {
  const lang = getLangCode();

  // 本地存储
  store.set(LOCAL_LANG, lang);
  return lang;
};

const getLangCode = () => {
  // 1. 先取 URL 中的 LANG
  const queryInfo: any = queryString.parse(window.location.search);

  if (queryInfo?.lang) {
    return queryInfo.lang;
  }

  // 2. 读取 Local Storage
  const localLang: any = store.get(LOCAL_LANG);

  if (localLang) {
    return localLang;
  }

  // 3. 读取浏览器语言
  const navLang = navigator.language;
  const hasLang = LANGUAGE_OPTIONS.some((item) => item.i18n === navLang);
  if (hasLang) return navLang;
  return LANGUAGE_OPTIONS[1].i18n;
};

i18n.use(initReactI18next).init({
  lng: initLang(),
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
});
