// export const CONFIG_DEBUG = window.__config.debug;
export const LOCAL_LANG = 'LOCAL_LANG'; // 系统语种
export const LOCAL_TRANSLATE_LANG = 'LOCAL_TRANSLATE_LANG'; // 机翻语种

export const ALL_APPS = {
  home: {
    name: 'APP_NAME_HOME',
    title: 'APP_TITLE_HOME',
    hasProject: false,
    needAuth: false,
    authApp: 'all',
    docApp: '',
    show: false,
  },
  user: {
    name: 'APP_NAME_USER',
    title: 'APP_TITLE_USER',
    hasProject: false,
    // 需要自己控制登录态, 因为登录选择页面会在这里出现
    needAuth: true,
    authApp: 'all',
    docApp: '',
    show: false,
  },
  doc: {
    name: 'APP_NAME_DOC',
    title: 'APP_TITLE_DOC',
    hasProject: false,
    needAuth: true,
    authApp: 'all',
    docApp: '',
    show: false,
    hubbleKey: 'MA-B573-19B8A56AAE71',
    overseaHubbleKey: 'MA-B944-1D322F78E022',
  },
  console: {
    name: 'APP_NAME_CONSOLE',
    title: 'APP_TITLE_CONSOLE',
    hasProject: true,
    needAuth: true,
    authApp: 'all',
    docApp: '',
    show: false,
  },
} as const;
