import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Dropdown, MenuProps } from 'antd';
import mos_icon_dark from '@images/mos_icon_dark.png';
import classNames from 'classnames';
import { GlobalOutlined } from '@ant-design/icons';
import useTranslation from '@/hooks/useTranslation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store';
import { setLang, setTranslateLang } from '@/store/slices/root';
import { useTranslation as useTranslationIl8n } from 'react-i18next';
import { LOCAL_LANG } from '@/config/constants';
import store from 'store';
import { getUserInfo } from '@/api';
import './index.scss';
import useLinkAddress from '@/hooks/useLinkAddress';

const RootAppHeaderNav: React.FC = () => {
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state: RootState) => state.root);
  const [selectLang, setSelectLang] = useState(lang);
  const [headerTabNum, setHeaderTabNum] = useState<number>(null);
  const { i18n } = useTranslationIl8n();
  const linkAddress = useLinkAddress();
  const headerTab: { name: string; link: string }[] = [
    {
      name: 'DOCS',
      link: linkAddress.docs,
    },
    {
      name: 'DEMO',
      link: linkAddress.demo,
    },
    {
      name: 'TUTORIALS',
      link: linkAddress.tutorials,
    },
    {
      name: 'SOURCE',
      link: linkAddress.source,
    },
  ];
  const items: MenuProps['items'] = [
    {
      key: 'zh',
      label: '中文',
    },
    {
      key: 'en',
      label: 'English',
    },
  ];
  // useEffect(() => {
  //   console.log('getUserInfo');
  //   getUserInfo()
  //     .then((res) => {
  //       console.log('getUserInfo res', res);
  //     })
  //     .catch((err) => {
  //       console.log('getUserInfo err', err);
  //     });
  // }, []);

  const handleHeaderClick = (link: string, index: number) => {
    setHeaderTabNum(index);
    window.open(link, '_blank');
  };

  const handleSelect = (key: string) => {
    dispatch(setLang(key));
    store.set(LOCAL_LANG, key);
    i18n.changeLanguage(key);
  };

  return (
    <div className="header_bg">
      <div className="bottom_bg"></div>
      <header className="flex-row">
        <img src={mos_icon_dark} alt=""></img>
        <div className="header-tab">
          {headerTab.map((item: any, index) => {
            return (
              <NavLink
                to={''}
                className={classNames('header-tabItem', headerTabNum === index ? 'active-tab' : 'default')}
                key={index}
                onClick={() => handleHeaderClick(item.link, index)}
              >
                {t(item.name)}
              </NavLink>
            );
          })}
          <Dropdown
            overlayClassName="header-dropdown"
            placement="bottom"
            menu={{
              items,
              selectable: true,
              defaultSelectedKeys: [lang],
              onSelect: ({ key }) => handleSelect(key),
            }}
          >
            <GlobalOutlined className="translateImg" />
          </Dropdown>
        </div>
      </header>
    </div>
  );
};

export default RootAppHeaderNav;
