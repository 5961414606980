const TRANSLATION = {
  DOCS: {
    zh: '文档',
    en: 'Docs',
  },
  DEMO: {
    zh: 'Demo',
    en: 'Demo',
  },
  TUTORIALS: {
    zh: '指引',
    en: 'Tutorials',
  },
  SOURCE: {
    zh: '资源',
    en: 'Source',
  },
  SOLUTION_FOR_DEVELOPING_GAME: {
    zh: '用于开发可扩展多人游戏后端的解决方案',
    en: 'Solution for developing scalable multiplayer game backend',
  },
  TEAM_FOCUS_GAME: {
    zh: '让团队更专注于游戏本身',
    en: 'Let the team focus more on the game',
  },
  GET_STARTED: {
    zh: '开始使用',
    en: 'Get Started',
  },
  WATCH_DEMO: {
    zh: '观看Demo',
    en: 'Watch Demo',
  },
  HIGHLIGHTS: {
    zh: 'Highlights',
    en: 'Highlights',
  },
  MATURE_AND_DEPENDABLE: {
    zh: '成熟稳定',
    en: 'Reliable and dependable',
  },
  MATURE_AND_DEPENDABLE_INTRODUCE: {
    zh: '架构经过验证的大DAU游戏验证',
    en: 'The architecture has been proven in the large DAU game',
  },
  POLISHED_AND_REFINED: {
    zh: '专业',
    en: 'Polished and refined',
  },
  POLISHED_AND_REFINED_INTRODUCE: {
    zh: '对于实时MMO的游戏支持程度高，多人游戏gameplay特性丰富',
    en: 'We provide excellent support for real-time MMO games and a variety of multiplayer capabilities',
  },
  POWERFUL_PERFORMANCE: {
    zh: '性能强大',
    en: 'Powerful Performance',
  },
  POWERFUL_PERFORMANCE_INTRODUCE: {
    zh: '追求性能终极优化，释放设计师的创造力',
    en: 'Pursues ultimate performance optimization, unleashing the creativity of designers',
  },
  SEAMLESS_CUSTOMIZATION: {
    zh: '易于定制',
    en: 'Seamless Customization',
  },
  SEAMLESS_CUSTOMIZATION_INTRODUCE: {
    zh: '容易理解的代码架构，易于进行自定义扩展',
    en: "With an easy-to-understand code structure, it's simple to make customized expansions",
  },
  SCALABILITY: {
    zh: '可扩展性',
    en: 'Scalability',
  },
  SCALABILITY_INTRODUCE: {
    zh: '根据在线规模灵活的动态伸缩，兼顾运营成本和负载上限',
    en: 'Capable of flexible dynamic scaling based on online size, balancing operational costs and maximum load',
  },
  DEDICATED_SERVICE: {
    zh: '服务完善',
    en: 'Dedicated Service',
  },
  DEDICATED_SERVICE_INTRODUCE: {
    zh: '完善的交付流程，全项目生命期的问题跟进',
    en: 'We offer a well-established delivery process, ensuring issues are addressed throughout the entire project lifecycle',
  },
  THIRD_PARTY_ECOSYSTEM: {
    zh: 'Third Party EcoSystem',
    en: 'Third Party EcoSystem',
  },
  THIRD_PARTY_ECOSYSTEM_INTRODUCE: {
    zh: 'MOS需要利用第三方生态来构建完整的游戏后端解决方案，以MOS核心服务器集群框架为中心，通过库，插件，微服务等形式扩展功能特性满足项目需求。 官方提供的Player Service和Gameplay模块，以低耦合原则进行插件式设计，可以根据不同游戏设计进行组装和修改。',
    en: 'MOS leverages a third-party ecosystem to construct a comprehensive game backend solution. Adhering to the principle of low-coupling in plug-in design, it allows for flexibility in assembly and modification to cater to various game designs.',
  },
  PLAYER_SERVICE: {
    zh: 'Player Service',
    en: 'Player Service',
  },
  CORE_FRAMEWORK: {
    zh: 'Core Framework',
    en: 'Core Framework',
  },
  GAMEPLAY_SYSTEM: {
    zh: 'Gameplay System',
    en: 'Gameplay System',
  },
  CLUSTER_MANAGEMENT: {
    zh: 'Cluster Management',
    en: 'Cluster Management',
  },
  GAMEPLAY_SYSTEM_INTRODUCE: {
    zh: '对应实现核心游戏玩法的Dedicated Server节点，并具有多进程扩展能力，适应大规模在线玩家。',
    en: 'Gameplay System corresponds to the Dedicated Server node that implements the core gameplay, and has multi-process expansion capabilities to adapt to large-scale online players.',
  },
  CLUSTER_MANAGEMENT_INTRODUCE: {
    zh: '提供多服务器集群的管理，通信，调度，监控及运维。分布式Entity的RPC通信，负载均衡以及容灾。对有状态服务集群进行管理。',
    en: "Cluster management provides management, communication, scheduling, monitoring and operation and maintenance of multi-server clusters. Distributed Entity's RPC communication, load balancing and disaster recovery. Manage stateful service clusters.",
  },
  PLAYER_SERVICE__INTRODUCE: {
    zh: '对应基础登录，支付，社交聊天，组队匹配等。可能会结合外部第三方服务。',
    en: 'Player service corresponds to basic login, payment, social chat, team matching, etc. May be combined with external third-party services.',
  },
  LEARN_MORE: {
    zh: '详情',
    en: 'Learn more',
  },
  BEST_PARCTICE: {
    zh: 'Best Practice',
    en: 'Best Practice',
  },
  WE_SUPPORT: {
    zh: 'We Also Support',
    en: 'We Also Support',
  },
  KICH_START_GAME: {
    zh: '开始你的游戏开发之旅',
    en: 'Kickstart Your Game Development Journey',
  },
  PROVIDE_HELP: {
    zh: '我们将提供全面帮助，以确保您的顺利使用',
    en: 'We are committed to providing comprehensive assistance to ensure a seamless user experience for you',
  },
  CONTACT_US: {
    zh: '联系我们',
    en: 'Contact us',
  },
  FANTASY_WESTWARD_JOURNEY: {
    zh: 'Fantasy Westward Journey',
    en: 'Fantasy Westward Journey',
  },
  CUTTING_EDGE_SPEED: {
    zh: 'Cutting-edge speed',
    en: 'Cutting-edge speed',
  },
  LIGHT_ENCOUNTER: {
    zh: 'light encounter',
    en: 'light encounter',
  },
  THE_SHORE_OF_THE_LAND: {
    zh: 'The shore of the land',
    en: 'The shore of the land',
  },
  EGGMAN_PARTY: {
    zh: 'Eggman Party',
    en: 'Eggman Party',
  },
  ENDLESS_LAGRANGE: {
    zh: 'endless lagrange',
    en: 'endless lagrange',
  },
  A_CHINESE_GHOST_STORY: {
    zh: 'A Chinese Ghost Story',
    en: 'A Chinese Ghost Story',
  },
  AFTER_TOMORROW: {
    zh: 'after tomorrow',
    en: 'after tomorrow',
  },
  FIFTH_PERSONALITY: {
    zh: 'fifth personality',
    en: 'fifth personality',
  },
} as const;

export default TRANSLATION;
