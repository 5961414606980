import React, { useEffect, useMemo, useRef, useState } from 'react';
import { cloneDeep, debounce } from 'lodash-es';
import classNames from 'classnames';
import { Tabs } from 'antd';

import useTranslation from '@/hooks/useTranslation';
import RootAppFooter from '@/components/footer';

import get_start from '@images/get_start.svg';
import watch_demo from '@images/watch_demo.svg';
import group_70 from '@images/group_70.png';
import unreal_engine_logo from '@images/unreal_engine_logo.png';
import nvidia_physX_logo from '@images/nvidia_physX_logo.png';
import ecoSystem_circle_1 from '@images/ecoSystem_circle_1.svg';
import ecoSystem_circle_2 from '@images/ecoSystem_circle_2.svg';
import ecoSystem_circle_3 from '@images/ecoSystem_circle_3.svg';
import ecoSystem_group_70 from '@images/ecoSystem_group_70.svg';
import wyLab from '@images/wyLab.png';
import uniMatrix_logo from '@images/uniMatrix_logo.png';
import grafana_img from '@images/grafana_img.png';
import grafana_text from '@images/grafana_text.png';
import prometheus_img from '@images/prometheus_img.png';
import prometheus_text from '@images/prometheus_text.png';
import redis from '@images/redis.png';
import mongo from '@images/mongo.png';
import game_play_system_tab from '@images/game_play_system_tab.png';
import support_bg from '@images/support_bg.png';
import westward_journey from '@images/westward_journey.png';
import chost_story from '@images/chost_story.png';
import edge_speed from '@images/edge_speed.png';
import eggman_party from '@images/eggman_party.png';
import endless_lagrange from '@images/endless_lagrange.png';
import fifth_personality from '@images/fifth_personality.png';
import light_encounter from '@images/light_encounter.png';
import after_tomorrow from '@images/after_tomorrow.png';
import shore_land from '@images/shore_land.png';
import support_get_start from '@images/support_get_start.svg';
import swiper_1 from '@images/swiper_1.png';
import swiper_2 from '@images/swiper_2.png';
import swiper_3 from '@images/swiper_3.png';
import swiper_1_active from '@images/swiper_1_active.png';
import swiper_2_active from '@images/swiper_2_active.png';
import swiper_3_active from '@images/swiper_3_active.png';
import speciallized from '@images/speciallized.png';
import customize from '@images/customize.png';
import mature from '@images/mature.png';
import powerful from '@images/powerful.png';
import scalability from '@images/scalability.png';
import service from '@images/service.png';

import './index.scss';
import useLinkAddress from '@/hooks/useLinkAddress';

const productList = [
  {
    img: mature,
    title: 'MATURE_AND_DEPENDABLE',
    text: 'MATURE_AND_DEPENDABLE_INTRODUCE',
  },
  {
    img: speciallized,
    title: 'POLISHED_AND_REFINED',
    text: 'POLISHED_AND_REFINED_INTRODUCE',
  },
  {
    img: powerful,
    title: 'POWERFUL_PERFORMANCE',
    text: 'POWERFUL_PERFORMANCE_INTRODUCE',
  },
  {
    img: customize,
    title: 'SEAMLESS_CUSTOMIZATION',
    text: 'SEAMLESS_CUSTOMIZATION_INTRODUCE',
  },
  {
    img: scalability,
    title: 'SCALABILITY',
    text: 'SCALABILITY_INTRODUCE',
  },
  {
    img: service,
    title: 'DEDICATED_SERVICE',
    text: 'DEDICATED_SERVICE_INTRODUCE',
  },
];

const supportList = [
  {
    img: westward_journey,
    name: 'FANTASY_WESTWARD_JOURNEY',
  },
  {
    img: edge_speed,
    name: 'CUTTING_EDGE_SPEED',
  },
  {
    img: light_encounter,
    name: 'LIGHT_ENCOUNTER',
  },
  {
    img: shore_land,
    name: 'THE_SHORE_OF_THE_LAND',
  },
  {
    img: eggman_party,
    name: 'EGGMAN_PARTY',
  },
  {
    img: endless_lagrange,
    name: 'ENDLESS_LAGRANGE',
  },
  {
    img: chost_story,
    name: 'A_CHINESE_GHOST_STORY',
  },
  {
    img: edge_speed,
    name: 'CUTTING_EDGE_SPEED',
  },
  {
    img: after_tomorrow,
    name: 'AFTER_TOMORROW',
  },
  {
    img: fifth_personality,
    name: 'FIFTH_PERSONALITY',
  },
];

const RootAppContainer: React.FC = () => {
  const t = useTranslation();
  const linkAddress = useLinkAddress();
  const tab: { label: string; key: string; text: string; link: string }[] = [
    {
      label: 'GAMEPLAY_SYSTEM',
      key: 'Gameplay System',
      text: 'GAMEPLAY_SYSTEM_INTRODUCE',
      link: linkAddress.gameplay,
    },
    {
      label: 'PLAYER_SERVICE',
      key: 'Player Service',
      text: 'PLAYER_SERVICE__INTRODUCE',
      link: linkAddress.player,
    },
    {
      label: 'CLUSTER_MANAGEMENT',
      key: 'Cluster Management',
      text: 'CLUSTER_MANAGEMENT_INTRODUCE',
      link: linkAddress.cluster,
    },
  ];
  const [swiperList, setSwiperList] = useState<{ img: any; active: boolean; img_active: any }[]>([
    {
      img: swiper_1,
      img_active: swiper_1_active,
      active: true,
    },
    {
      img: swiper_2,
      img_active: swiper_2_active,
      active: false,
    },
    {
      img: swiper_3,
      img_active: swiper_3_active,
      active: false,
    },
  ]);
  const [activekey, setActiveKey] = useState(0);
  let timer = useRef(null);
  let activeTimer = useRef(null);
  let currentIndex = 0;
  useEffect(() => {
    const _dom = document.querySelectorAll('.ant-tabs-tab-btn');
    _dom.forEach((item, index) => {
      const label: any = tab[index].label;
      item.setAttribute('tab-name', t(label)); // 用于设置hover时的产生宽度变化导致抖动问题
    });
    // setTimerInterval();
    return () => {
      // clearInterval(timer.current);
      // clearTimeout(activeTimer.current);
    };
  }, []);

  const setTimerInterval = () => {
    if (!timer.current) {
      timer.current = setInterval(() => {
        currentIndex++;
        if (currentIndex > swiperList.length - 1) {
          currentIndex = 0;
        }
        handleSwiperSlide(currentIndex);
      }, 3000);
    }
  };

  const handleSwiperSlide = (index: number) => {
    const _swiperList = swiperList.map((item, i) => {
      return {
        ...item,
        active: i === index,
      };
    });
    setSwiperList(cloneDeep(_swiperList));
    activeTimer.current = setTimeout(() => {
      setActiveKey(index);
    }, 200);
  };

  const handleDotClick = (index: number) => {
    // 需要先清除定时器
    clearInterval(timer.current);
    timer.current = null;
    currentIndex = index;
    handleSwiperSlide(index);
    setTimerInterval();
  };

  // 鼠标移入事件
  const handleOnMouseEnter = debounce((e, index) => {
    clearInterval(timer.current);
    timer.current = null;
    const activeIndex = swiperList.findIndex((item) => item.active);
    if (index === activeIndex) return;
    handleSwiperSlide(index);
  }, 200);

  // 鼠标移出事件
  const handleOnMouseLeave = debounce((e, index) => {
    currentIndex = index;
    setTimerInterval();
  }, 200);

  const handleToLink = (link: string) => {
    window.open(link, '_blank');
  };

  const renderTabItem = (item) => {
    return (
      <div className="tab-container">
        <div className="game_play_system_tab_img">
          <img src={game_play_system_tab}></img>
        </div>
        <div className="game_play_system_tab_text">
          <h1 className="title">{t(item.label)}</h1>
          <div className="descripit">{t(item.text)}</div>
          <div className="lear-more-btn" onClick={() => handleToLink(item.link)}>
            <div className="lear-more-text">{t('LEARN_MORE')}</div>
            <svg xmlns="http://www.w3.org/2000/svg" width="31" height="23" viewBox="0 0 31 23" fill="none">
              <path
                d="M1.59513 10.367L9.84464 4.26703L16.2628 10.4497C16.6671 10.8392 16.6717 11.4851 16.2729 11.8802L9.84464 18.2495L1.58431 11.967C1.05366 11.5634 1.05907 10.7634 1.59513 10.367Z"
                fill="white"
              />
              <path
                d="M30.5234 11.0942L15.2937 0.197021L12.7335 2.16639L22.5234 10.3347C22.9992 10.7317 23.0032 11.4614 22.5317 11.8634L12.7335 20.2189L15.2937 22.1226L30.5234 11.0942Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
    );
  };

  return (
    <main className="root-app-main container">
      <div className="top_bg">
        <div className="top">
          <div className="top-text-title">{t('SOLUTION_FOR_DEVELOPING_GAME')}</div>
          <div className="top-text">{t('TEAM_FOCUS_GAME')}</div>
          <div className="flex-row flex-align-items-center">
            <div className="common-btn get-start-btn tw-mr-[20px]" onClick={() => handleToLink(linkAddress.docs)}>
              <div className="btn-text margin-right-8">{t('GET_STARTED')}</div>
              <div className="photo-box">
                <img src={get_start} className="start-photo"></img>
              </div>
            </div>
            <div className="common-btn watch-demo-btn" onClick={() => handleToLink(linkAddress.demo)}>
              <div className="btn-text tw-mr-[8px]">{t('WATCH_DEMO')}</div>
              <img src={watch_demo} className="watch-photo"></img>
            </div>
          </div>
          <div className="guide">
            <img src={group_70}></img>
          </div>
        </div>
      </div>
      <div className="product">
        <div className="product-title">{t('HIGHLIGHTS')}</div>
        <div className="product-list">
          {productList.map((item: any, index) => {
            return (
              <div className="product-item" key={index}>
                <div className="mask"></div>
                <img src={item.img} alt="" className="product-item-photo"></img>
                <div className="product-item-bottom">
                  <div className="tw-mb-[10px] product-item-bottom-title">{t(item.title)}</div>
                  <div className="product-item-bottom-text">{t(item.text)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bg_1">
        <div className="ecoSystem">
          <div className="tw-text-[38px] tw-font-bold tw-mb-[20px] tw-text-center">{t('THIRD_PARTY_ECOSYSTEM')}</div>
          <div className="tw-text-[18px] ecoSystem-text">{t('THIRD_PARTY_ECOSYSTEM_INTRODUCE')}</div>
          <div className="ecoSystem-contain">
            <div className="tw-flex tw-justify-between">
              <div className="tw-relative">
                <div className="ecoSystem_circle">
                  <img src={ecoSystem_group_70} className="ecoSystem_group_small"></img>
                  <img src={ecoSystem_circle_1}></img>
                </div>
                <img src={ecoSystem_group_70} className="ecoSystem_group_large"></img>
                <div className="common-system gamepiay-system">
                  <div className="tw-mb-[12px] tw-pl-[23px] tw-relative tw-z-[3]">{t('GAMEPLAY_SYSTEM')}</div>
                  <div className="tw-flex tw-relative tw-z-[3]">
                    <div className="unreal_engine">
                      <img src={unreal_engine_logo}></img>
                    </div>
                    <div className="nvidia_physX">
                      <img src={nvidia_physX_logo}></img>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-relative">
                <div className="ecoSystem_circle">
                  <img src={ecoSystem_group_70} className="ecoSystem_group_small"></img>
                  <img src={ecoSystem_circle_2}></img>
                </div>
                <img src={ecoSystem_group_70} className="ecoSystem_group_large"></img>
                <div className="common-system player-service">
                  <div className="margin-bottom-12 tw-relative tw-z-[3]">{t('PLAYER_SERVICE')}</div>
                  <div className="flex-row tw-relative tw-z-[3]">
                    <div className="wyLab">
                      <img src={wyLab}></img>
                    </div>
                    <div className="uniMatrix_logo">
                      <img src={uniMatrix_logo}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-relative">
              <div className="ecoSystem_circle">
                <img src={ecoSystem_group_70} className="ecoSystem_group_small"></img>
                <img src={ecoSystem_circle_3}></img>
              </div>
              <img src={ecoSystem_group_70} className="ecoSystem_group_large"></img>
              <div className="common-system core-framework">
                <div className="margin-bottom-12 tw-relative tw-z-[3]">{t('CORE_FRAMEWORK')}</div>
                <div className="flex-row tw-relative tw-z-[3]">
                  <div className="comomm-core-framework grafana">
                    <div className="grafana-img">
                      <img src={grafana_img}></img>
                      <img src={grafana_text}></img>
                    </div>
                  </div>
                  <div className="comomm-core-framework prometheus">
                    <div className="prometheus-img">
                      <img src={prometheus_img}></img>
                      <img src={prometheus_text}></img>
                    </div>
                  </div>
                  <div className="comomm-core-framework redis">
                    <img src={redis}></img>
                  </div>
                  <div className="comomm-core-framework mongo">
                    <img src={mongo}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middle-box">
          <div className="tab">
            <Tabs
              defaultActiveKey="1"
              centered
              items={tab.map((item: any, i) => {
                return {
                  label: t(item.label),
                  key: item.key,
                  children: renderTabItem(item),
                };
              })}
            />
          </div>
        </div>
      </div>
      {/* tood： 轮播图后续待优化 */}
      {/* <div className="bg_2">
        <div className="tw-flex swiper">
          <div className="tw-text-[38px] tw-font-bold title">Best Practice</div>
          <div className="tw-flex">
            {swiperList.map((item, index) => {
              return (
                <div
                  className={classNames('swiper_box', { active: item.active })}
                  key={index}
                  onMouseEnter={(e) => handleOnMouseEnter(e, index)}
                  onMouseLeave={(e) => handleOnMouseLeave(e, index)}
                >
                  <div className="swiper_text_box">
                    <div className="tw-relative tw-z-[3] swiper_text">
                      <div className="tw-text-[30px] tw-font-bold tw-mb-[10px]">Game name</div>
                      <div className="tw-text-[20px] tw-mb-[10px]">
                        The back cliff of Lingshan turned into a dragon pool, the white horse bathed and washed, receded its fur,
                        changed its head horns, golden scales emerged, and the four claws of auspicious clouds turned into a
                        dragon horse.clouds turned into.
                      </div>
                      <div className="tw-flex tw-align-center tw-mr-[10px] tw-mb-[20px] tw-justify-end">
                        <div className="tw-font-bold tw-text-[20px] tw-mr-[10px]">{t('LEARN_MORE')}</div>
                        <img src={support_get_start}></img>
                      </div>
                    </div>
                  </div>
                  <img src={activekey === index ? item.img_active : item.img} className="swiper_1" alt="" />
                </div>
              );
            })}
          </div>
          <div className="tw-flex swiper-dot">
            {swiperList.map((item, index) => {
              return (
                <div
                  className={classNames('dot', { active: item.active })}
                  key={index}
                  onClick={() => handleDotClick(index)}
                ></div>
              );
            })}
          </div>
        </div>
      </div> */}
      <div className="bg_3">
        {/* <div className="support">
          <div className="support-title">{t('WE_SUPPORT')}</div>
          <div className="tw-flex tw-items-center tw-flex-wrap support-list">
            {supportList.map((item: any, index) => {
              return (
                <div className="tw-relative support-item" key={index}>
                  <Tooltip
                    placement="bottom"
                    title={t(item.name)}
                    overlayClassName="support-tooltip"
                    color={'#fff'}
                    zIndex={1}
                    autoAdjustOverflow={false}
                  >
                    <div className="mask"></div>
                  </Tooltip>
                  <img src={support_bg} className="support_bg"></img>
                  <img src={item.img} className="tw-relative tw-z-[3] tw-mt-[70px] tw-mb-[6px]"></img>
                  <div className="support-item-text tw-relative tw-z-[3]">{t(item.name)}</div>
                </div>
              );
            })}
          </div>
        </div> */}
        <div className="tw-h-[180px]"></div>
        <div className="help tw-relative">
          <div className="help-title">{t('KICH_START_GAME')}</div>
          <div className="help-text">{t('PROVIDE_HELP')}</div>
          <div className="flex-row flex-align-items-center">
            <div className="common-btn get-start-btn margin-right-20" onClick={() => handleToLink(linkAddress.docs)}>
              <div className="btn-text margin-right-8">{t('GET_STARTED')}</div>
              <img src={support_get_start}></img>
            </div>
            <div className="common-btn contact-btn" onClick={() => handleToLink(linkAddress.popo)}>
              <div className="btn-text margin-right-8">{t('CONTACT_US')}</div>
              <img src={support_get_start}></img>
            </div>
          </div>
        </div>
        <RootAppFooter />
      </div>
    </main>
  );
};

export default RootAppContainer;
