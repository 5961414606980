import LocalRoutes from '@/route';
import RootAppHeaderNav from './components/header';
import './App.scss';
import '@/i18n/config';

const App = () => {
  return (
    <>
      <RootAppHeaderNav />
      <LocalRoutes />
    </>
  );
};

export default App;
