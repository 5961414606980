import { updateName } from '@/store/slices/root';
import React from 'react';
import { useDispatch } from 'react-redux';

import './index.scss';

type TProps = {};

const Doc: React.FC<TProps> = React.memo(() => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(updateName('wick'));
  };

  return <div>doc</div>;
});

export default Doc;
