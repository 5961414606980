import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StateType } from '../types/root';
import store from 'store';
import { LOCAL_TRANSLATE_LANG, LOCAL_LANG } from '@/config/constants';

const initState: StateType = {
  name: '',
  translateLang: store.get(LOCAL_TRANSLATE_LANG) || '', // 机翻语言
  lang: store.get(LOCAL_LANG) || '', // 系统语种，用户可设置
};

export const slice = createSlice({
  name: 'root',
  initialState: initState,
  reducers: {
    updateName(state: StateType, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setLang(state: StateType, action: PayloadAction<StateType['lang']>) {
      state.lang = action.payload;
    },
    setTranslateLang(state: StateType, action: PayloadAction<StateType['translateLang']>) {
      state.translateLang = action.payload;
      store.set(LOCAL_TRANSLATE_LANG, action.payload);
    },
  },
});

export const { updateName, setTranslateLang, setLang } = slice.actions;
export default slice.reducer;
