import React, { useEffect, useRef, useState } from 'react';
import { History } from 'history';
import LocalRoutes from '@/route';
// import { RootAppContainer, RootAppHeaderNav } from './components';

import './index.scss';
import RootAppContainer from './components/root-app-container';

const Root: React.FC<{
  history?: History;
}> = ({ history }) => {
  return (
    <div className="main-container">
      <RootAppContainer />
    </div>
  );
};

export default Root;
