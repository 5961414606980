import store from 'store';
import { LOCAL_LANG } from '@/config/constants';

const useLinkAddress = () => {
  const lang = store.get(LOCAL_LANG) || 'en';
  const linkAddress = {
    docs: `https://mos.doc.nie.netease.com/mos_doc/${lang}/`,
    demo: `https://mos.doc.nie.netease.com/mos_doc/${lang}/content/sample_and_tutorial/mmo_demo/mmo_demo_overview.html`,
    tutorials: `https://mos.doc.nie.netease.com/mos_doc/${lang}/content/sample_and_tutorial`,
    source: 'http://git-internal.nie.netease.com/mos',
    cluster: `https://mos.doc.nie.netease.com/mos_doc/${lang}/content/cluster_management`,
    gameplay: `https://mos.doc.nie.netease.com/mos_doc/${lang}/content/gameplay_system`,
    player: `https://mos.doc.nie.netease.com/mos_doc/${lang}/content/player_service`,
    unitrace: `https://unisdk.nie.easebar.com/doc/page/appdump-english/${lang}/introduction/product-introduction`,
    unimatrix: `https://unisdk.nie.easebar.com/doc/page/unimatrix/${lang}/introduction`,
    unipublish: 'https://easepublish.nie.easebar.com/home',
    popo: 'http://popo.netease.com/static/html/open_popo.html?ssid=1441205&sstp=1',
  };
  return linkAddress;
};
export default useLinkAddress;
