import { Navigate, Route, Routes } from 'react-router-dom';
// import Home from '@/pages/home';

import Root from './containers/root';
import { RootAppContainer } from '@/containers/root/components';
import Doc from '@/pages/doc';

function LocalRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Root />} />
      <Route path="/doc" element={<Doc />} />
      <Route path="*" element={<Navigate to={`/`} replace={true} />} />
    </Routes>
  );
}

export default LocalRoutes;
