import React from 'react';
import net_ease from '@images/net_ease.png';
import './index.scss';
import useLinkAddress from '@/hooks/useLinkAddress';

const RootAppFooter: React.FC = () => {
  const linkAddress = useLinkAddress();
  const footerList: { name: string; link: string }[] = [
    {
      name: 'UniTrace',
      link: linkAddress.unitrace,
    },
    {
      name: 'UniMatrix',
      link: linkAddress.unimatrix,
    },
    {
      name: 'UniPublish',
      link: linkAddress.unipublish,
    },
  ];
  const handleToLink = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <footer className="footer">
      <div className="list">
        {footerList.map((item, index) => (
          <div className="list-item" key={index} onClick={() => handleToLink(item.link)}>
            {item.name}
          </div>
        ))}
      </div>
      <div className="logo tw-flex tw-flex-col">
        <img src={net_ease} className="net_ease"></img>
      </div>
    </footer>
  );
};

export default RootAppFooter;
